<template>

<!--用户管理 校园管理 年级管理 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <router-link :to="{name: 'managementStudentSchoolGradeManagementAdd', query: {schoolId: schoolId}}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorGetNewGradeData"
        @selection-change="handleSelectionChange"
        stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="gradeName"
          label="年级类型">
          <template slot-scope="scope">
            <p>
              {{getGradeType(scope.row.gradeType)}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="gradeName"
          label="年级名称">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementStudentSchoolGradeManagementEdit', params: {item: scope.row, schoolId: schoolId}}">
              <el-button
                size="mini"
                type="text"
                >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <router-link :to="{name: 'managementStudentSchoolClassManagement', query: {gradeId: scope.row.id,  schoolId: schoolId, gradeName: scope.row.gradeName }}">
              <el-button
                size="mini"
                type="text"
                >班级</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="showDel(scope.row.id)"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <el-button @click="delAllShow">
        批量删除
      </el-button>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      AdministratorGetNewGradeDataSelection: [],
      schoolName: null,
      schoolId: null,
      AdministratorGetNewGradeData: []
    }
  },
  created () {
    this.schoolId = storage.get('schoolId')
    this.getData()
  },
  methods: {
    getGradeType (val) {
      let data = ''
      switch (Number(val)) {
        case 0:
          data = '幼儿园'
          break
        case 1:
          data = '小学'
          break
        case 2:
          data = '初中'
          break
        case 3:
          data = '高中'
          break
        case 4:
          data = '大学'
          break
      }
      return data
    },
    handleSelectionChange (val) {
      this.AdministratorGetNewGradeDataSelection = val
    },
    del (id) {
      AdministratorService.AdministratorDelNewGrade({ list: id }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getData()
      })
    },
    delAllShow () {
      if (this.AdministratorGetNewGradeDataSelection.length === 0) {
        this.$message({
          type: 'info',
          message: '请选中要删除的年级'
        })
        return
      }
      this.$confirm('此操作将永久删除选中年级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(this.AdministratorGetNewGradeDataSelection.map(item => {
          return item.id
        }))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该年级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del([id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getData () {
      if (!this.schoolId) return
      AdministratorService.AdministratorGetNewGrade({ schoolId: this.schoolId }).then(res => {
        this.AdministratorGetNewGradeData = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top:10px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
